




import Vue from "vue";
import OpenDataComponent from "../components/OpenDataComponent.vue";

export default Vue.extend({
  name: "OpenData",

  components: {
    OpenDataComponent,
  },
});
