














































































































































import Vue from "vue";
import content from "@/content.json";
import { AxiosResponse } from "axios";
import ProgressTracker from "@/components/lib/ProgressTracker.vue";

export default Vue.extend({
  name: "OpenDataComponent",
  components: {
    ProgressTracker,
  },
  data: () => ({
    page: content.pages.opendata,
    error: "",
    success: "",
    files: [] as File[],
    state: {
      init: true,
      ready: false,
      finished: false,
    },
    dialog: {
      upload: false,
    },
    trackerName: {
      upload: "",
      download: "",
      downloadFinished: ""
    },
    loading: {
      upload: false,
      download: false,
      downloadFinished: false
    },
  }),
  methods: {
    downloadInstructions(){
      this.$request({
        url: `${this.$store.state.APIurl}/return_opendata/download-instructions`,
        method: "POST",
        responseType: "blob",
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "OpenData uitleg.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => {
          this.success = "";
          this.error =
            "Het bestand kan niet worden geproduceerd. Neem contact op met de beheerder";
        })
    },
    downloadFile() {
      this.loading["download"] = true;
      this.trackerName["download"] = `spend-download-opendata-${Date.now()}`;
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      this.$request({
        url: `${this.$store.state.APIurl}/return_opendata/download-results?tracker_name=${this.trackerName["download"]}`,
        method: "POST",
        responseType: "blob",
        headers: headers,
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "opendata.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          console.log(error.response.text());
          this.success = "";
          this.error =
            "Het bestand kan niet worden geproduceerd. Neem contact op met de beheerder";
        });
    },
    isReady() {
      this.$request(
        `${this.$store.state.APIurl}/return_opendata/opendata-status`
      )
        .then((response: any) => {
          this.state["init"] = false;
          this.state['finished'] = response.data.finished;
          this.state['ready'] = response.data.isReady && !response.data.finished;
        })
        .catch((error: any) => {
          console.log(error.response);
        });
    },
    postFile() {
      this.dialog["upload"] = false;
      this.loading["upload"] = true;
      this.trackerName["upload"] = `spend-upload-opendata-${Date.now()}`;

      const formData = new FormData();
      for (let i = 0; i < this.$data.files.length; i++) {
        formData.append("documents", this.$data.files[i]);
      }
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      const url = `${this.$store.state.APIurl}/return_opendata/upload?tracker_name=${this.trackerName["upload"]}`;
      this.$request
        .post<FormData, AxiosResponse<any>>(url, formData, {
          headers: headers,
        })
        .then((response: any) => {
          console.log(response);
          this.$data.error = undefined;
          this.success = this.page.l_dropzone.success;
          this.state['finished'] = true;
          this.state['ready'] = false
        })
        .catch((error: any) => {
          this.success = "";
          this.error = error.response.data.detail;
        });
      this.$data.files = [];
    },
    closeProgressTracker() {
      this.loading["download"] = false;
      this.loading["upload"] = false;
      this.error = "";
      this.success = "";
    },
    // required for uploading components
    clickDropZone() {
      document.getElementById("hidden-file-input")?.click();
    },
    uploadFile(e: { target: { files: any } }) {
      this.files = e.target.files;
    },
    dragFile(e: { dataTransfer: { files: any } }) {
      this.files = e.dataTransfer.files;
      if (this.files.length > 1) {
        // only allow a single file
        this.files = [this.files[0]];
      }
    },
  },
  created() {
    this.isReady();
  },
});
